import React from 'react'
import Seo from '../../../components/Seo'
import BigTitle from '../../../components/BigTitle'
import DefaultSection from '../../../components/DefaultSection'
import Article from '../../../components/Article'
import TeaserSection from '../../../components/TeaserSection'
import TeaserHeader from '../../../components/TeaserHeader'
import IndividualContactSection from '../../../components/IndividualContactSection'
import NavigationLink from '../../../components/NavigationLink'


const Page = () => {
  return (
    <React.Fragment>
      <Seo title="Studien- und Abschlussarbeiten"
           description="Kombiniere Studium mit einem Maximum an Praxisluft! Schreibe Deine Studien- bzw. Abschlussarbeit mit BUSCHMAIS als Dein Praxispartner."/>
      <BigTitle title="Stellenangebot" imageRelativePath={'Titelbild_Karriere.jpg'} className="mask-black"
                textInverted={true}
                menuInverted={true} logoInverted={true}>Du bist auf der Suche nach einem
        Praxispartner für Deine Studien- oder Abschlussarbeit? Dann bist Du hier genau richtig.</BigTitle>

      <DefaultSection>
        <Article>
          <h1>Studien- und Abschlussarbeiten</h1>
          <p>Du steckst mitten in der Planung Deiner Studien- oder Abschlussarbeit und bist auf der Suche nach einem
            innovativen
            Praxis- und Forschungspartner, mit dem Du Dir gemeinsam ein zukunftsweisendes Thema erarbeiten kannst? Dann
            freuen
            wir uns auf Deine aussagekräftige Bewerbung. </p>
          <h2>Deine Aufgabe</h2>
          <p>Wir bieten Dir interessante Themen in folgenden Bereichen:
            <ul>
              <strong>Software Analytics:</strong>
              <li>Erarbeitung eines Vorgehens zur Analyse von Legacy-Software</li>
              <li>Visualisierung von Strukturen und Metriken komplexer Systeme</li>
              <li>Quantifizierung von Qualitätseigenschaften zur Entscheidungsunterstützung</li>
            </ul>
            <ul>
              <strong>Qualitätssicherung und Dokumentation:</strong>
              <li>Qualitative Bewertung von Softwaresystemen</li>
              <li>Kontinuierliches Softwarequalitäts-Management</li>
              <li>Einbettung kontinuierlicher Architekturdokumentation im Entwicklungsprozess</li>
              <li>Technische Möglichkeiten zur Dokumentation von Architekturkonzepten im Code</li>
              <li>Nutzen und Grenzen der Definition von Referenzarchitekturen</li>
            </ul>

            <p>Solltest Du im Voraus Fragen zu einzelnen Themen haben, komme gerne auf uns zu. Weitere Informationen zu
              unseren Themen findest Du hier:
              <ul>
                <li>Software Architekturen analysieren, dokumentieren und verbessern <NavigationLink
                  to={'http://st.inf.tu-dresden.de/files/teaching/ws19/ring/Software%20Architekturen%20analysieren,%20dokumentieren,%20verbessern.pdf'}
                  ariaLabel={'Vortragsslides'} asTextLink>(Vortragsslides)</NavigationLink></li>
                <li>Leichtgewichtige Architekturdokumentation mit ADRs <NavigationLink
                  to={'https://github.com/jqassistant-tutorials/jqassistant-101/blob/master/architecture-decision-records/index.adoc'}
                  ariaLabel={'Tutorial'} asTextLink>(Tutorial)</NavigationLink></li>
                <li>Dokumentation von Architekturkonzepten mit Annotationen mit jMolecules <NavigationLink
                  to={'https://github.com/xmolecules/jmolecules'}
                  ariaLabel={'Tutorial'} asTextLink>(Tutorial)</NavigationLink> bzw. jQAssistant DDD
                  Plugin <NavigationLink
                    to={'https://github.com/jqassistant-tutorials/jqassistant-101/blob/master/ddd-plugin/index.adoc'}
                    ariaLabel={'Tutorial'} asTextLink>(Tutorial)</NavigationLink></li>
                <li>Einführung in jQAssistant und Dokumentation mit Architecture Decision Records <NavigationLink
                  to={'https://vimeo.com/638976964'}
                  ariaLabel={'Vortragsaufzeichnung'} asTextLink>(Vortragsaufzeichnung)</NavigationLink></li>
                <li>Einführung in Software Analytics <NavigationLink
                  to={'https://www.youtube.com/watch?v=fmdMYc3RfKY&t=9s'}
                  ariaLabel={'Vortragsaufzeichnung'} asTextLink>(Vortragsaufzeichnung)</NavigationLink></li>
              </ul>
            </p>

            <p>Wir sind offen für eigene Themenvorschläge und begrüßen es, wenn Du im Vorfeld bei uns ein Praktikum
              absolvierst
              oder uns als Werkstudent (m/w/d) kennenlernst.</p>

            <p>Im Rahmen Deiner Studien- bzw. Abschlussarbeit kannst Du Deine theoretischen Kenntnisse aus dem Studium
              um praktische Erfahrungen
              erweitern. Nebenbei lernst Du uns und den Berateralltag kennen und die Kolleg*innen sind jederzeit für ein
              Gespräch offen.</p>
          </p>
          <h2>Dein Profil</h2>
          <p>
            <ul>
              <li>Im Studium der Informatik oder eines verwandten Studiengangs hast Du bereits umfassende theoretische
                Kenntnisse zu Deinem Fachgebiet erlangt, die Du nun praktisch anwenden möchtest.
              </li>
              <li>Du hegst eine starke Affinität zur Softwareentwicklung und hast bereits erste Erfahrungen mit einer
                Programmiersprache sammeln können.
              </li>
              <li>Du verfügst über eine schnelle Auffassungsgabe, Engagement und Flexibilität.
              </li>
              <li>Neben sehr guten Deutschkenntnissen (mind. C1 Niveau) und guten Englischkenntnissen besitzt Du soziale
                Kompetenz und Begeisterungsfähigkeit.
              </li>
            </ul>
          </p>
          <h2>Deine Benefits bei BUSCHMAIS</h2>
          <p>
            <ul>
              <li>Dich erwarten eine kompetente Einarbeitung durch einen persönlichen Mentor, sowie eine intensive
                Betreuung und regelmäßiges Feedback.
              </li>
              <li>Du sammelst praktische Erfahrungen im Umgang mit modernen Technologien.</li>
              <li>Du erhältst eine angemessene Vergütung.</li>
              <li>Nach individueller Absprache ist regelmäßiges Homeoffice kein Problem.</li>
              <li>Profitiere von unserer bisherigen Kooperation mit diversen Hochschulen wie z. B. der TU Dresden und
                der Universität Leipzig.
              </li>
              <li>Langeweile kommt bei uns nicht auf, denn wir veranstalten Ausflüge und Grillabende und nehmen an
                Sportevents teil.
              </li>
              <li>Mit einem erfolgreichen Abschluss bieten wir Dir Perspektiven bei BUSCHMAIS.</li>
            </ul>
          </p>
        </Article>
      </DefaultSection>

      <TeaserSection className="bg-level-1" anchor="bewerben">
        <TeaserHeader title="Begeistert?">
          <p>
            Das freut uns. Schreibe uns gerne eine E-Mail mit Deinen Vorstellungen und Deinem Lebenslauf. Für Rückfragen stehen wir Dir gerne zur Verfügung.
          </p>
          <p>Bitte beachte, dass Bewerbungen über die E-Mail-Dienste
            Outlook und Gmail aus technischen Gründen ggf. nicht bei uns ankommen.</p>
          <p className="mt-5">
            <a className="btn btn-outline-black" href="mailto:jobs@buschmais.com"
               title="Jetzt per Mail bewerben">Jetzt per Mail bewerben</a>
          </p>
        </TeaserHeader>
      </TeaserSection>

      <IndividualContactSection personKey={'christiane'} email={'jobs@buschmais.com'} tel={'+49 351 320923 11'}/>

    </React.Fragment>
  )
}

export default Page
